jQuery(document).ready(function(){

    // Grayscale w canvas method
    function grayscale(src){
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        var imgObj = new Image();
        imgObj.src = src;
        canvas.width = imgObj.width;
        canvas.height = imgObj.height;
        ctx.drawImage(imgObj, 0, 0);
        var imgPixels = ctx.getImageData(0, 0, canvas.width, canvas.height);
        for(var y = 0; y < imgPixels.height; y++){
            for(var x = 0; x < imgPixels.width; x++){
                var i = (y * 4) * imgPixels.width + x * 4;
                var avg = (imgPixels.data[i] + imgPixels.data[i + 1] + imgPixels.data[i + 2]) / 3;
                imgPixels.data[i] = avg;
                imgPixels.data[i + 1] = avg;
                imgPixels.data[i + 2] = avg;
            }
        }
        ctx.putImageData(imgPixels, 0, 0, 0, 0, imgPixels.width, imgPixels.height);
        return canvas.toDataURL();
    }

    function validCSS(property,value){
        var div = jQuery("<div>");
        var _old = div.css(property);
        div.css(property,value);
        var _new = div.css(property);
        return (_old!==_new);
    }

    jQuery( '.js-masonry-grid__item > img' ).each( function() {
        // 'Check' if grayscale is applied
        if( !validCSS('filter', 'grayscale(100%)') ) {
            // If the css-rule doesn't apply -> generate grayscaled image
            var imagesrc = jQuery(this).attr('src');
            var image = new Image();
            image.src = imagesrc;

            if( image.width !== 0 ) {
                jQuery(this).attr('src', grayscale( imagesrc ));
            }
        }
    });

});