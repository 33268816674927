/**
 * cubetech Numbers Plugin
 * This plugins adds buttons to number fields, which can be styled the way you want.
 * @author Sven von Arx, cubetech
 */
jQuery( document ).ready( function(  ){

    var selector = 'input[type="number"], .cubetech-numbers';

    /**
     * add 1 to value
     * @param value int
     * @return int
     */
    function addNumber( value, maxVal ) {

        // value not smaller than zero and not empt
        if( value === '' ) { // set value zero
            value = 1;
        }
        else if( maxVal === undefined || parseInt(maxVal) > parseInt(value) ) {
            value++;
        }

        return value;

    }

    /**
     * subtract 1 to value
     * @param value int
     * @return int
     */
    function subNumber( value, minVal ) {

        // value not smaller than zero and not empty
        if( value === '' ) { // set value zero
            value = 0;
        }
        else if( minVal === undefined || parseInt(minVal) < parseInt(value) ) {
            value--;
        }

        return value;

    }

    // loop all number inputs and initilize plugin
    jQuery( selector ).each( function(  ){
        if ( jQuery(this).is('[readonly]') ) { return; }
        // add wrapper div
        jQuery( this ).wrap( '<div class="cubetech-number-field"></div>' );

        // add control buttons
        jQuery( this ).parent( '.cubetech-number-field' ).append( '<div class="cubetech-plus">+</div>' );
        jQuery( this ).parent( '.cubetech-number-field' ).append( '<div class="cubetech-minus">–</div>' );

        // bind click events
        jQuery( this ).siblings( '.cubetech-plus' ).on( 'click', function(  ){

            // get actual value
            value = jQuery( this ).siblings( selector ).val();
            maxVal = jQuery( this ).siblings( selector ).attr('max');

            // interact with value
            if ( jQuery( this ).siblings( selector ).attr('readonly') === 'readonly' || jQuery( this ).siblings( selector ).attr('disabled') === 'disabled' ) {
                value = value;
            } else {
                value = addNumber( value, maxVal );
            }
            // set new value
            jQuery( this ).siblings( selector ).val( value ).trigger('numberChanged').trigger('change');
        } );

        jQuery( this ).siblings( '.cubetech-minus' ).on( 'click', function(  ){

            // get actual value
            value = jQuery( this ).siblings( selector ).val();
            minVal = jQuery( this ).siblings( selector ).attr('min');

            // interact with value
            if ( jQuery( this ).siblings( selector ).attr('readonly') === 'readonly' || jQuery( this ).siblings( selector ).attr('disabled') === 'disabled' ) {
                value = value;
            } else {
                value = subNumber( value, minVal );
            }


            // set new value
            jQuery( this ).siblings( selector ).val( value ).trigger('numberChanged').trigger('change');

        } );

    } );

} );