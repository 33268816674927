jQuery(document).ready(function( $ ){
	function transform( ele, value ) {
			ele.css({
					'-webkit-transform' : 'translate(' + value + ')',
					'-moz-transform'    : 'translate(' + value + ')',
					'-ms-transform'     : 'translate(' + value + ')',
					'-o-transform'      : 'translate(' + value + ')',
					'transform'         : 'translate(' + value + ')'
			});
	}

	if($(window).width() < 768){


	    /*
	     * Responsive Nav
	     */

	    var defaultOpts = {
	        maxWindowWidth : '767',
	        parentLinkClickable : false
	    };

	    var isClickHandlerBound = false;

	    var opts = defaultOpts;

	    var mainMenu = $('.navbar-nav.nav');

	    var currentMenuDepth = 0;

	    var hasSubMenuItems = $('li.menu-item-has-children > a');

	    var subMenuClickHandler = function ( e ) {
	        e.preventDefault();


	        // Save Element in Variable to append to menu
	        var parentMenuItem = jQuery(this).parent();
	        var parentMenuLink = jQuery(this);
	        var subMenu = jQuery('> ul', parentMenuItem);

	        if( opts.parentLinkClickable ) {
	            subMenu.prepend( '<li class="parent-link">' + parentMenuLink.get(0).outerHTML + '</li>' );
	        }
	        subMenu.prepend( '<li class="btn-back">' + parentMenuLink.get(0).outerHTML + '</li>' );

	        parentMenuItem.addClass('drop-active');
	        $(mainMenu).addClass("second-level-open");

	        // Increase Menu Depth
	        currentMenuDepth++;

	        var menuTranslation = currentMenuDepth * 100;

	        transform( mainMenu, -menuTranslation + '%' );
	    };
	    var temp;
		$(".navbar-toggle").click(function(){
			currentMenuDepth = 0;
			$(mainMenu).removeClass("second-level-open");
			$(mainMenu).css('transform', 'translate(0%)');
			var menu = jQuery(".menu-item-has-children");
			if($(menu).hasClass("drop-active")){
				$(menu).removeClass("drop-active");
			}
			$(".btn-back").remove();
			//used to measure distance scrolled
			temp = $(window).scrollTop();
		});

	    mainMenu.on('click', '.btn-back', function() {
	        $(this).parent('ul').parent('li').removeClass('drop-active');
	        $(mainMenu).removeClass("second-level-open");
	        $(this).siblings('.parent-link').remove();
	        $(this).remove();

	        currentMenuDepth--;

	        var menuTranslation = currentMenuDepth * 100;

	        transform( mainMenu, -menuTranslation + '%' );
	    });


	    $(window).on('load resize', function(){
	        if ($(window).width() > opts.maxWindowWidth){
	            // Remove all parent links
	            $( '.parent-link, .btn-back, .drop-active' ).remove();
	            if( isClickHandlerBound ) {
	                hasSubMenuItems.unbind('click', subMenuClickHandler);
	            }
	            currentMenuDepth = 0;
	            transform( mainMenu, '0%' );
	        } else {
	            if( !isClickHandlerBound ) {
	                hasSubMenuItems.bind( 'click', subMenuClickHandler );
	                isClickHandlerBound = true;
	            }
	        }
	    });

	    //Function to close the navigation on scroll
	    $(window).scroll(function(){
		    //prevents unwanted closing
		    if ($(this).scrollTop() - temp >= 10 || $(this).scrollTop() - temp <= -10){
			    //check if nav is open
				if($("body").hasClass("nav-open")){
					//check if in is still on nav
				    if($("nav.navbar-right").hasClass("in")){
					    if($("nav.navbar-right").attr("aria-expanded", "true")){
					    	$("body").removeClass("nav-open");
					    }
					    $("button.navbar-toggle").click(function(){
							if($("body").hasClass("nav-open")){
								$("body").removeClass("nav-open");
							}else{
								$("body").addClass("nav-open");
							}
					    });
					    $("nav.navbar-right").removeClass("in");
					    $("nav.navbar-right").attr("aria-expanded", "false");
					    $(".menu-item-has-children").removeClass("second-level-open");
					    $(".menu-item-has-children").removeClass("drop-active");
					    currentMenuDepth = 0;
				    }
			    }
		    }
	    });
	}

});
