/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        correctShortcodeNesting();

        $('.js-slick-slide').slick({

            infinite: true,

            speed: 350,

            fade: true,

            cssEase: 'linear',
        });

        $('.event-data').find('#search').val('Junge Erwachsene');
        var cloned = $('.event-data').find('#grid').clone();
        var eventHtml = '';

        $('.event-data .event').each(function(){
          var el = $(this)[0];
          if (el.parentElement.classList.contains('filter_target_group_6')) {
            data = {
              title : $(this).find('h2').text(),
              content : $(this).find('.teaser p').text(),
              date : $(this).find('.date-time').text(),
              link : $(this).find('a').attr('href'),
            };
            eventHtml += '<tr class="agenda__row"><td class="agenda__left-data agenda--column"> <strong>' + data.date.substr(12) + '</strong> </td> <td class="agenda__right-data agenda--column"> <strong>' + data.title + '</strong><br> <div class="teaser"> <p>' + data.content + '</p> </div><a class="agenda__link" target="_blank" href="https://www.ref-sg.ch/' + data.link + '"><strong>Details zum Anlass</strong></a> </td> </tr>';
          }
        });

        setTimeout(function(){
          if($('.agenda tbody').length > 0){
            $('.agenda tbody').append(eventHtml);
          }else{
            $('.agenda').append('<tbody></tbody>');
            $('.agenda tbody').append(eventHtml);
          }
          $('.event-data').children().remove();
          $('.event-data').append(cloned);
          $('#mobile-show').remove();
        }, 2000);

        $('.cubetech-minus, .cubetech-plus').hover( function() {
          $(this).parent().addClass('green');
        }, function() {
          $(this).parent().removeClass('green');
        });

        var fieldsHidden = true;
        $('#billing_address').on('change', function() {
          if (fieldsHidden) {
            $('.form--hidden').addClass('form--visible');
            fieldsHidden = false;
          } else {
            $('.form--hidden').removeClass('form--visible');
            fieldsHidden = true;
          }
        });


        //close lightbox by clicking outside
        $(function(){
          $(document.body)
              .on('click touchend','#swipebox-slider .current img', function(e){
                  return false;
              })
              .on('click touchend','#swipebox-slider .current', function(e){
                  $('#swipebox-close').trigger('click');
              });
        });

        //hover navigation
	    if(jQuery(window).width() > 768){
	        $('.dropdown').hover( function(){
	          $(this).addClass('open');
	        }, function() {
	          $(this).removeClass('open');
	        });
	    }

        $('.js-gallery_thumbs__wrap').click(function() {
         var idx = $(this).find('.js-gallery_thumbs__item').attr('data-index');
         $('.js-slick-slide').slick('slickGoTo', parseInt(idx));
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

function correctShortcodeNesting(){
  jQuery('.gallery-shortcode').each(function(){
    jQuery(this).height(jQuery(this).find('.gallery-list').outerHeight() + 10);
    jQuery(this).height(jQuery(this).find('.project-gallery__wrap').outerHeight() + 10);
  });
}

function scrollto( ele ) {
    ele = jQuery(ele);
    jQuery('html, body').animate({
        scrollTop: ele.offset().top - jQuery('.navbar').height()
    }, 500);
}
function triggerClick( ele ) {
    jQuery(ele).trigger('click');
    jQuery('.js-hide-after-submit').css('display', 'none');
}

jQuery(window).load(function(){
    $grid = jQuery('.js-masonry-grid').masonry({
        itemSelector: '.js-masonry-grid__item',
        gutter : 0,
        percentPosition: true,
    });
    $grid.imagesLoaded().done(function () {
        $grid.masonry('layout');
    });
});
