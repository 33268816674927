// form.js
(function($) {
	var hidden_fieldname;
	$(document).ready(function(){
		hidden_fieldname = $('form[name="product_selection"]').data('product-hidden-field-name');
		if(hidden_fieldname === '' && window.console){
			window.console.error("hidden fieldname not defined");
		}else{
			$('.js-order_item').on( 'change', function() {
	            var order = [];
	            $('form[name="product_selection"] .js-order_item').each( function() {
	                if( $(this).val() > 0 ) {
	                    order.push( $(this).parents('.gfield').find( 'label').html() + ' x ' + $(this).val() );
	                }
	            });
	            $('form.form-lieferangaben input[name="'+hidden_fieldname+'"]').val( order.join(', ') );
	        });
		}
	});
})(jQuery);